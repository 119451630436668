<template>
  <div class="container">
    <div class="content">
      <h5>Featured</h5>
      <div class="company-details">
        <img :src="featuredCase.logo || null" :alt="featuredCase.company" class="company-logo">
        <div class="company-name">
          <div class="company-type" v-if="featuredCase.sectors">{{featuredCase.sectors.join(' - ')}}</div>
          <h4>{{featuredCase.company}}</h4>
        </div>
      </div>
      <div class="company-description" v-html="getCompanyDescription(featuredCase.description)"></div>
      <div class="company-role">
        <img :src="featuredCase.profile" :alt="featuredCase.name" class="company-profile">
        <div class="company-person">
          <h5>{{featuredCase.name}}</h5>
          <p>{{featuredCase.role}}</p>
        </div>
      </div>
      <router-link class="btn" :to="`/case-studies/${featuredCase.company}`">Explore this case study</router-link>
    </div>
    <div class="company-image">
      <img :src="featuredCase.image" :alt="featuredCase.company">
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import DateFormat from "./DateFormat.vue";

export default {
  name: "FeaturedCase",
  components: {
    Icon,
    DateFormat,
  },
  props: {
    featuredCase: {
      type: Object,
    },
  },
  methods: {
    getCompanyDescription(content) {
      return content.replace(/\n/g, '<br>');
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 16px;
  margin-right: 144px;
  min-height: 670px;
  padding: 42px 0;
  box-shadow: 0 12px 48px rgba(101, 102, 98, 0.12);
}

.content {
  width: calc(50% + 72px);
  flex-shrink: 0;
  padding: 0 80px 0 96px;
}

.content > h5 {
  text-transform: uppercase;
}

.content .company-details {
  display: flex;
  align-items: center;
  margin: 32px 0;
}

.content .company-details .company-type {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.content .company-logo {
  width: 96px;
  height: 96px;
  border-radius: 16px;
  object-fit: cover;
  margin-right: 24px;
}

.content .company-role {
  display: flex;
  align-items: center;
  margin: 32px 0 40px;
}

.content .company-role .company-profile {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 24px;
}

.company-description {
  font-size: 1.125rem;
}

.company-image {
  position: absolute;
  top: 42px;
  bottom: 42px;
  right: -144px;
  width: calc(50% + 72px);
}

.company-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

@media screen and (max-width: 1280px) {
  .content {
    padding: 0 42px 0 64px;
  }
}

@media screen and (max-width: 920px) {
  .container {
    padding: 0 48px;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }
  .content {
    width: auto;
    max-width: none;
    padding: 48px 0;
  }
  .company-image {
    position: relative;
    top: 0;
    right: auto;
    width: 100%;
    height: 300px;
    margin: 0 0 48px;
  }
  .company-image img {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .container {
    padding: 0 32px;
  }
  .content {
    padding: 32px 0;
  }
  .company-image {
    margin-bottom: 32px;
    height: 220px;
  }
}
</style>
